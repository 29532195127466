import Api from '@/services/Index';

const getAll = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/course/schedules/attendance', config);
}
const storeAttendance = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/course/schedules/attendance', formData);
}
const updateAttendance = async (id,formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put('/course/schedules/attendance/'+id,formData);
}
export default {
    getAll,
    storeAttendance,
    updateAttendance
}
