<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <div class="col-12">
                    <ValidationProvider name="status" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('attendance_status')">
                            <parameter-selectbox
                                v-model="form.status"
                                :validate-error="errors[0]"
                                code="course_schedule_attendance_statuses"
                            >
                            </parameter-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                </div>
            </b-row>
        </ValidationObserver>
        <div class="col-12 mt-3 d-flex">
            <b-button  type="button" @click="store" variant="primary" class="btn-lg mr-2">
                {{ $t('save').toLocaleUpperCase('tr-TR') }}
            </b-button>
        </div>
    </div>
</template>
<script >
import CourseSchedulesAttendance from "@/services/CourseSchedulesAttendance";
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
export default {
    components:{
        ParameterSelectbox

    },
    props:{
        formId:{
            type:Number
        },
    },
    data(){
        return{
            form:{},
        }
    },
    methods:{
        async store() {
            let isValid = await this.$refs.formModalValidate.validate()
            if (isValid) {
                let formData = {
                    status: this.form.status
                }
                return CourseSchedulesAttendance.updateAttendance(this.formId,formData)
                    .then(response => {
                        this.$toast.success(this.$t('api.' + response.data.message));
                        this.$emit('successNoteEnterUpdate',true)
                    }).catch(err => {
                        this.showErrors(err)
                    })
            }
        }
    }
}
</script>
