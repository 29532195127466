<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('instructor_attendance')"
                        :isColumns="true"
                        @filter-div-status="datatable.filterStatus = $event"
                >
                    <template v-slot:columns>
                        <div
                            class="mb-1"
                            v-for="(column, key) in datatable.columns"
                            v-bind:key="key"
                        >
                            <b-form-checkbox
                                :id="'checkbox-' + key"
                                :name="'checkbox-' + key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field != 'buttons'"
                            >
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('instructor_attendance')"
                              :isColumns="true"
                              @filter-div-status="datatable.filterStatus = $event"
                >
                    <template v-slot:columns>
                        <div
                            class="mb-1"
                            v-for="(column, key) in datatable.columns"
                            v-bind:key="key"
                        >
                            <b-form-checkbox
                                :id="'checkbox-' + key"
                                :name="'checkbox-' + key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field != 'buttons'"
                            >
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </HeaderMobile>
            </template>
            <datatable-filter
                v-show="datatable.filterStatus"
                @filterClear="filterSet"
                @filter="filter"
            >
                <ValidationObserver ref="CourseSchedulesAttendanceForm">
                    <b-row>
                        <b-col sm="6" md="3" lg="3" xl="3">
                            <ValidationProvider name="faculty_code" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('faculty')">
                                    <faculty-selectbox
                                        v-model="datatable.queryParams.filter.faculty_code"
                                        :validate-error="errors[0]"
                                    ></faculty-selectbox>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col sm="6" md="3" lg="3" xl="3">
                            <b-form-group :label="$t('department')">
                                <department-selectbox
                                    :faculty_code="datatable.queryParams.filter.faculty_code"
                                    v-model="datatable.queryParams.filter.department_code"
                                ></department-selectbox>
                            </b-form-group>
                        </b-col>
                        <b-col sm="6" md="3" lg="3" xl="3">
                            <b-form-group :label="$t('program')">
                                <program-selectbox
                                    :faculty_code="datatable.queryParams.filter.faculty_code"
                                    :department_code="datatable.queryParams.filter.department_code"
                                    v-model="datatable.queryParams.filter.program_code"
                                ></program-selectbox>
                            </b-form-group>
                        </b-col>
                        <b-col sm="6" md="3" lg="3" xl="3">
                            <b-form-group :label="$t('teaching_staff')">
                                <staff-auto-complete
                                    v-model="datatable.queryParams.filter.instructor_id"
                                ></staff-auto-complete>
                            </b-form-group>
                        </b-col>
                        <b-col sm="6" md="3" lg="3" xl="3">
                            <b-form-group :label="$t('course')">
                                <course-auto-complete
                                    v-model="datatable.queryParams.filter.course_id"
                                ></course-auto-complete>
                            </b-form-group>
                        </b-col>
                        <b-col sm="6" md="3" lg="3" xl="3">
                            <b-form-group :label="$t('section')">
                                <b-form-input
                                    v-model="datatable.queryParams.filter.section"
                                    type="number"
                                ></b-form-input>
                            </b-form-group>
                        </b-col>

                        <b-col sm="6" md="3" lg="3" xl="3">
                            <ValidationProvider name="semester_id" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('semester')">
                                    <semesters-selectbox
                                        :setActive="true"
                                        :validate-error="errors[0]"
                                        v-model="datatable.queryParams.filter.semester_id">
                                    </semesters-selectbox>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col sm="6" md="3" lg="3" xl="3">
                            <ValidationProvider name="date" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('date')" class="position-relative">
                                    <v-date-picker v-model="datatable.queryParams.filter.date" :locale="$i18n.locale"
                                                   :masks="masks"
                                                   @input="changeDate"
                                                   :validateError="errors[0]"
                                                  >
                                        <template v-slot="{ inputValue, inputEvents }">
                                            <b-input-group>
                                                <b-form-input :readonly="true" :value="inputValue"
                                                              v-on="inputEvents"
                                                              :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <b-input-group-append>
                                                    <b-button variant="outline-secondary" class="btn-40" disabled>
                                                        <i class="ri-calendar-line"></i></b-button>
                                                </b-input-group-append>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>

                                            </b-input-group>

                                        </template>
                                    </v-date-picker>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                </ValidationObserver>
            </datatable-filter>
            <CommonModal ref="createFormModal" size="xl" :onHideOnlyX="true" @bHideModalHeaderClose="formClear">
                <template v-slot:CommonModalTitle>
                    {{ $t('attendance_create').toUpper() }}
                </template>
                <template v-slot:CommonModalContent>
               <CreateAttendance :formId="formId"  :date="date"  v-if="formProcess=='create'" @successNoteEnter="processCanSuccess"/>
                </template>
            </CommonModal>
            <CommonModal ref="updateFormModal" size="xl" :onHideOnlyX="true" @bHideModalHeaderClose="formClear">
                <template v-slot:CommonModalTitle>
                    {{ $t('attendance_update').toUpper() }}
                </template>
                <template v-slot:CommonModalContent>
                    <UpdateAttendance :formId="formId"    v-if="formProcess=='update'" @successNoteEnterUpdate="successNoteEnterUpdate"/>
                </template>
            </CommonModal>
            <datatable
                :isLoading.sync="datatable.isLoading"
                :columns="datatable.columns"
                :rows="datatable.rows"
                :total="datatable.total"
                :queryParams="datatable.queryParams"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-per-page-change="onPerPageChange"
                v-show="datatable.showTable"
            >
            </datatable>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
import DatatableFilter from "@/components/datatable/DatatableFilter";
import Datatable from "@/components/datatable/Datatable";
import CourseScheduleService from "@/services/CourseScheduleService";
import AcademicYearsSelectbox from "@/components/interactive-fields/AcademicYearsSelectbox";
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
import CommonModal from "@/components/elements/CommonModal";
import {ValidationProvider, ValidationObserver} from "vee-validate";
import qs from "qs";
import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox";
import StaffAutoComplete from "@/components/interactive-fields/StaffAutoComplete";
import CampusSelectbox from "@/components/interactive-fields/CampusSelectbox";
import BuildingSelectbox from "@/components/interactive-fields/BuildingSelectbox";
import ClassroomSelectbox from "@/components/interactive-fields/ClassroomSelectbox";
import CourseAutoComplete from "@/components/interactive-fields/CourseAutoComplete";
import DepartmentSelectbox from "@/components/interactive-fields/DepartmentSelectbox";
import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox";
import SemestersSelectbox from "@/components/interactive-fields/SemestersSelectbox";
import DaysSelectbox from "@/components/interactive-fields/DaysSelectbox";
import importForm from "@/modules/courseSchedule/pages/importForm";
import CourseSchedulesAttendance from "@/services/CourseSchedulesAttendance";
import DocumentRequestTypeSelectbox from "@/components/interactive-fields/DocumentRequestTypeSelectbox.vue";
import TimePicker from "@/components/elements/TimePicker.vue";
import DetailForm from "@/modules/documentRequest/pages/DetailForm.vue";
import CreateAttendance from "@/modules/courseSchedulesAttendance/pages/CreateAttendance.vue";
import UpdateAttendance from "@/modules/courseSchedulesAttendance/pages/updateAttendance.vue";
import moment from "moment/moment";



export default {
    components: {
        UpdateAttendance,
        CreateAttendance,
        DetailForm,
        DocumentRequestTypeSelectbox,
        AppLayout,
        Header,
        TimePicker,
        HeaderMobile,
        DatatableFilter,
        Datatable,
        CommonModal,
        ValidationProvider,
        ValidationObserver,
        AcademicYearsSelectbox,
        ParameterSelectbox,
        FacultySelectbox,
        StaffAutoComplete,
        CampusSelectbox,
        BuildingSelectbox,
        ClassroomSelectbox,
        CourseAutoComplete,
        DepartmentSelectbox,
        ProgramSelectbox,
        SemestersSelectbox,
        DaysSelectbox,
        importForm,
    },
    metaInfo() {
        return {
            title: this.$t("instructor_attendance"),
        };
    },
    data() {
        return {
            loading: false,
            date:null,
            masks: {
                input: "DD-MM-YYYY",
            },
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: "",
                        field: "buttons",
                        html: true,
                        sortable: false,
                        tdClass: "p-0 text-center w-40 align-middle",
                        buttons: [
                            {
                                text: this.$t("process_can"),
                                class:'ri-file-add-line top-minus-1',
                                callback: (row) => {
                                    this.processCan(row.id,row.date);
                                },
                                permission: 'courseschedule_storeattendance',
                                show:(data)=>{
                                    return data.attendance_status===null ?true:false
                                }

                            },
                            {
                                text: this.$t('edit'),
                                class: 'ri-edit-box-line',
                                permission: 'courseschedule_updateattendance',
                                show:(data)=>{
                                    return data.attendance_status===null ? false : true
                                },
                                callback: (row) => {
                                    this.updateProcessCan(row.id)
                                }
                            },
                        ],
                    },
                    {
                        label: this.toUpperCase("id"),
                        field: "id",
                        sortable: false,
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase("academic_year"),
                        field: "academic_year",
                        sortable: false,
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase("semester"),
                        field: "semester",
                        sortable: false,
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase("faculty_name"),
                        field: "faculty_name",
                        sortable: false,
                        hidden: false,
                    },

                    {
                        label: this.toUpperCase("instructor"),
                        field: "instructor_name",
                        sortable: false,
                        hidden: false,
                        formatFn: (value, row) => {
                            return row.instructor_name + " " + row.instructor_surname;
                        },
                    },
                    {
                        label: this.toUpperCase("course_code"),
                        field: "course_code",
                        sortable: false,
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase("course_name"),
                        field: "course_name",
                        sortable: false,
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase("section"),
                        field: "section",
                        sortable: false,
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase("classroom"),
                        field: "classroom",
                        sortable: false,
                        hidden: false,
                    },

                    {
                        label: this.toUpperCase("date"),
                        field: "date",
                        sortable: false,
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase("day"),
                        field: "day_name",
                        sortable: false,
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase("status"),
                        field: "attendance_status",
                        sortable: false,
                        // html: true,
                        hidden: false,
                        // formatFn:(row)=>{
                        //     if(row==="Geldi"){
                        //         return `<button type="button" class="btn btn-success" disabled>${row}</button>`;
                        //     }else if(row==="gelmedi"){
                        //         return `<button type="button" class="btn btn-danger" disabled>${row}</button>`;
                        //     }else if(row==="Yoklama Aldı"){
                        //        return `<button type="button" class="btn btn-warning" disabled>${row}</button>`;
                        //     }else if (row===null){
                        //         this.showEdit=true
                        //         return row
                        //     }
                        // }
                    },
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    // sort: "-id",
                    page:1,
                    limit: 20,
                },
            },
            showEdit:false,
            formProcess: null,
            formId: null,
            form: {},
            orjDate:null
        };
    },
    created() {
        this.filterSet();
    },

    methods: {

        filterSet() {
            this.datatable.queryParams.filter = {
                academic_year: null,
                semester_id: null,
                faculty_code: null,
                instructor_id: null,
                course_id: null,
                date: null,
                department_code: null,
                program_code: null,
                section: null,
            };
        },
        formClear(){
          this.formId=null
           this.formProcess=null
        },
        filter() {
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {

            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        changeColumn(key) {
            this.datatable.columns[key].hidden =
                this.datatable.columns[key].hidden == true ? false : true;
        },
        onSortChange(params) {
            const sortType = params[0].type == "desc" ? "-" : "";
            this.datatable.queryParams.sort = sortType + params[0].field;
            this.getRows();
        },
        changeDate(item){
            this.datatable.queryParams.filter.date=moment(item).format('DD-MM-YYYY')
        },
      async  getRows() {
            let isValid = await  this.$refs.CourseSchedulesAttendanceForm.validate()
            if(isValid){
                this.datatable.showTable = true;
                this.datatable.isLoading = true;
            const config = {
                params:{
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            };
            return CourseSchedulesAttendance.getAll(config)
                .then((response) => {
                    this.datatable.rows = response.data.data;
                    this.datatable.total = response.data.pagination.total;
                })
                .finally(() => {
                    this.datatable.isLoading = false;

                });
            }
        },
        processCan(id,date){
            this.formProcess='create'
            this.formId=id
            this.date=date
            this.$refs.createFormModal.$refs.commonModal.show()
        },
        processCanSuccess(){
            this.formClear()
            this.$refs.createFormModal.$refs.commonModal.hide()
            this.getRows()
        },
        updateProcessCan(id){
            this.formProcess='update'
            this.formId=id
            this.$refs.updateFormModal.$refs.commonModal.show()

        },
        successNoteEnterUpdate(){
            this.formClear()
            this.$refs.updateFormModal.$refs.commonModal.hide()
            this.getRows()
        }
    },
};
</script>

